var Injector = Injector || {};
Injector.lorien = (function(d) {

    var app = {};
    var pageType = '';
    var assetType = '';

    var cache = function() {
        if (typeof digitalData === 'object' && typeof digitalData.page === 'object' && typeof digitalData.page.category === 'object' && typeof digitalData.page.attributes === 'object') {
            pageType = digitalData.page.category.pageType || '';
            assetType = digitalData.page.attributes.assetType || '';
        }

        app.config = {
            slot: `
              .js-lyta-content > p,
              .js-lyta-content > h2,
              .js-lyta-content > h3,
              .js-lyta-content > h4,
              .js-lyta-content > h5,
              .js-lyta-content .paywall-content > p,
              .js-lyta-content .paywall-content > h2,
              .js-lyta-content .paywall-content > h3,
              .js-lyta-content .paywall-content > h4,
              .js-lyta-content .paywall-content > h5
              `,
            contentAdSpace: 16,
            injectInsideElement: false,
            injectAfterSlot: 4,
            minSlots: 6,
            waitForElement: true
        };
        if (Lyta.isMaggioView()) {
            app.config.contentAdIds = ['contentTop', 'content1', 'content2', 'content3', 'content4', 'content5'];
        } else {
            app.config.contentAdIds = ['content1', 'contentTop', 'content2', 'content3', 'content4', 'content5'];
        }
    };

    app.init = function() {
        cache();
        if (app.config.hasOwnProperty('runBefore')) app.config.runBefore();

        if (canBeInjected()) {
            if (app.config.waitForElement) {
                // Injects content ads when element exists
                var elementExist = setInterval(function() {
                    if (d.querySelectorAll(app.config.slot).length) {
                        injectAds();
                        clearInterval(elementExist);
                    }
                }, 100);

                if (app.config.hasOwnProperty('runAfter')) app.config.runAfter();
            } else {
                injectAds();
                if (app.config.hasOwnProperty('runAfter')) app.config.runAfter();
            }
        }
    };

    // Define values on which pages content ads can be injected
    var canBeInjected = function() {
        var commercialTypes = ['commercial', 'advertisement'];
        return (pageType == 'article' && commercialTypes.indexOf(assetType) === -1)
    };

    var injectElement = function(el, referenceNode, injectInsideElement) {
        if (injectInsideElement) {
            referenceNode.appendChild(el);
        } else {
            referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
        }
    };

    var elementToInject = function(elementId, lazyLoad) {
        var osElement = d.createElement('div');
        osElement.id = elementId;
        osElement.setAttribute("data-placement-id", elementId);
        if (lazyLoad) {
            osElement.className = 'lyta-lazy-load';
        }
        return osElement;
    };

    var findAdSlot = function (slots, start) {
        // Check that the slot has two elements (p, h2, h3, h4 or h5) before and after.
        // Ignore two last iterations, there has to be two elements after the slot.
        var start = (typeof start !== 'undefined') ?  start - 1 : app.config.injectAfterSlot - 1;
        var validElems = ["P", "H2", "H3", "H4", "H5"];
        var validCurrentElems = ["P"];
        var slotsCount = slots.length - 2;
        var slot = 0;

        for (var i = start; i < slotsCount; i++) {
            if (slots[i].previousElementSibling === null || slots[i].nextElementSibling === null) {
                // Skip if there is no previous or next element. This happens when
                // the slot is at the beginning or end of the paywall content.
                continue;
            }
            var prevElemStatus = validElems.indexOf(slots[i].previousElementSibling.tagName);
            var curElemStatus = validCurrentElems.indexOf(slots[i].tagName);
            var nextElemStatus = validElems.indexOf(slots[i].nextElementSibling.tagName);
            var secondElementPos = i + 1;
            var secondElementStatus = validElems.indexOf(slots[secondElementPos].nextElementSibling.tagName);

            if (curElemStatus != -1 && prevElemStatus != -1 && nextElemStatus != -1 && secondElementStatus != -1) {
                slot = i;
                break;
            }
        }
        return slot;
    };

    var injectContentAds = function(slots, start) {
        var startSlot = start;
        var adSlot = 0;

        for (let i = 0; i < app.config.contentAdIds.length; i++) {
            adSlot = findAdSlot(slots, startSlot);
            if (adSlot > 0) {
                var lazyLoad = (i > 0) ? true : false;
                injectElement(elementToInject(app.config.contentAdIds[i], lazyLoad), slots[adSlot], app.config.injectInsideElement);
                callAdvertisement(app.config.contentAdIds[i]);
                Lyta.log('Injector: injected ' + app.config.contentAdIds[i] + ' ad unit');
            }
            startSlot += app.config.contentAdSpace;
        }

        if (window.lazyLoadInstance && LytaConfig.lazyLoadAds === true) {
            window.lazyLoadInstance.update();
            Lyta.log('Injector: updated lazy load instance');
        }
    };

    var callAdvertisement = function(container) {
        try {
            Lyta.render({
                adUnit: container,
                async: true,
                checkVisibility: true,
                adLabels: true,
            });
        } catch (error) {
            try {
                Lyta.log(error);
            } catch (logerror) {
                console.log(error);
            }
        }

    };

    var injectAds = function() {
        var slots = d.querySelectorAll(app.config.slot);
        Lyta.log('Injector detected ' + slots.length + ' slots');

        if (slots.length >= app.config.injectAfterSlot) {
            var firstSlot = findAdSlot(slots);
            // minSlots is defined
            if (app.config.hasOwnProperty('minSlots') && typeof app.config.minSlots === 'number') {
                if (slots.length >= app.config.minSlots && firstSlot > 0) {
                    Lyta.log('Injector found enough valid slots. Detected ' + slots.length + ', required ' + app.config.minSlots);
                    // Inject content ads
                    injectContentAds(slots, firstSlot);
                } else {
                    Lyta.log('Injector: Did not inject. Not enough valid slots. Detected ' + slots.length + ', required ' + app.config.minSlots);
                }
            // minSlots is not defined
            } else {
                if (firstSlot > 0) {
                    Lyta.log('Injector found enough valid slots. Detected ' + slots.length + ' slots.');
                    // Inject content ads
                    injectContentAds(slots, firstSlot);
                } else {
                    Lyta.log('Injector: Did not inject. Not enough valid slots. Detected ' + slots.length);
                }
            }
        } else {
            Lyta.log('Injector: Did not inject. Not enough slots. Detected ' + slots.length + ', required ' + app.config.injectAfterSlot);
        }
    };

    app.init(); // self-invoking version

}(window.document));
