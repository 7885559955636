window.Lyta = function() {
    const FORMATID = 0;
    const ENABLED_FOR_CLIENT = 1;
    const ADUNIT_NAME = 2;
    const RESPONSIFY = 3;
    const DESKTOP_CONTENT_FORMAT_ID = 63814;
    const BOX_FORMAT_FORMAT_ID = 63002;
    const MOBILE_CONTENT_FORMAT_ID = BOX_FORMAT_FORMAT_ID;
    const DESKTOP_PARADE_ID = 63010;
    const MOBILE_PARADE_ID = 63009;
    const OUTSTREAM_FORMAT_ID = 63825;
    const INTERSTITIAL_ADS = ['interstitiaali', 'mobiiliinterstitiaali'];
    const SPECIAL_ADS = ['erikoismainos', 'mobiilierikoismainos'];
    const TOP_ADS = [
        'ylaparaati',
        'mobiiliylaparaati',
    ];
    const OUT_OF_PAGE_ADS = [].concat(INTERSTITIAL_ADS, SPECIAL_ADS);
    const PRIORITY_ADS = OUT_OF_PAGE_ADS;
    const INTERSTITIAL_COOKIE = 'interstitial';
    const INTERSTITIAL_COOKIE_EXPIRY = 60*15; // 15 minutes
    const ELEMENT_FIT_THROTTLE = 50; // Element fit throttle in ms
    const AD_QUEUE_DELAY = 100; // Default delay for queued ads
    const LYTA_UPDATE_EVENT = 'Lyta::Update';
    const LYTA_THROTTLED_FIT_EVENT = 'Lyta::Fit';
    const TCF_READY_EVENT = 'Lyta::TCFReady';
    const LABEL_TIMEOUT = 500;
    const MAGGIO_APPLICATION_SITE = 'maggio-application';
    const BELOW_CONTENT_NAME = 'belowcontent';


    let Lyta = {};
    Lyta.initialized = false;
    Lyta.version = VERSION; // Set by webpack.DefinePlugin
    Lyta.isMaggioView = () => {
        return LytaConfig.site == MAGGIO_APPLICATION_SITE;
    };

    // Integration functionality for Relevant Programmatic
    // To enable Relevant Programmatic on the page, use the following recipe:
    //
    // <script>
    //     var LytaConfig = {
    //         ...
    //         relevant: {
    //             enabled: true, // if not set to true, Relevant Programmatic will be bypassed
    //             scriptUrl: '[URL per site provided by Relevant]', // Relevant-script, can be loaded separately instead
    //             configId: '[ID per site provided by Relevant]',
    //             stdCall: true, // Needed for separate targeting per format (but might make ads load slower..)
    //         },
    //    ...
    var Relevant = {
        use: false, // Shorthand for LytaConfig.relevant.enabled
        config: (window.LytaConfig || {}).relevant || {},
        setupOptions: null, // sas.setup options
        queuedAds: [], // queued formats, later requested by Relevant.flushFormats()
        onNoAdCb: null, // noad-callback, set by Relevant.setOnNoad()
        init: function() {
            this.use = this.config.enabled;
            if (!this.use) {
                return;
            }
            if (this.config.scriptUrl) {
                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.config.scriptUrl;
                document.head.appendChild(script);
            }
        },
        withRelevant: function (fn) {
            window.relevantDigital = window.relevantDigital || {};
            relevantDigital.cmd = relevantDigital.cmd || [];
            relevantDigital.cmd.push(fn);
        },
        setOnNoad: function(cb) {
            this.onNoAdCb = cb;
            if (!this.use) {
                sas.cmd.push(function () {
                    sas.events.on("noad", cb);
                });
            }
        },
        setup: function (setupOptions) {
            this.setupOptions = setupOptions;
            if (!this.use) {
                sas.cmd.push(function () {
                    sas.setup(setupOptions);
                });
            }
        },
        // Use callNow == true to make the call immediately when using Relevant - else
        // the format will be queued until Relevant.flushFormats() is called. Purpose is to
        // avoid multiple calls / auctions for formats that are requested simultaneously.
        callFormat: function (params, callNow) {
            if (this.use) {
                this.queuedAds.push(params);
                if(callNow) {
                    this.flushFormats();
                }
            } else {
                sas.cmd.push(function () {
                    sas.call("std", params);
                });
            }
        },
        // Triggers a Prebid auction + ad-request(s) for queued formats.
        flushFormats: function () {
            Lyta.group('Relevant: flushFormats ...');
            var formats = this.queuedAds.splice(0);
            Lyta.log(JSON.parse(JSON.stringify(formats)));
            if (!formats.length) {
                return;
            }
            var that = this;
            this.withRelevant(function () {
                // If LytaConfig.relevant.stdCall is NOT true, then Smart will be called using 'onecall' and
                // siteId / pageId / target will be copied from the first format (so they will be the same for all formats).
                var fst = formats[0];
                var allowedDivIds = formats.map(function (fmt) {
                    var elm = document.getElementById(fmt.tagId);
                    if (elm) {
                        // The Relevant script is using the 'data-ad-unit-id' attribute to read the format-id
                        // needed when div id is not on sas_??? format.
                        elm.setAttribute('data-ad-unit-id', '' + fmt.formatId);
                    }
                    return fmt.tagId;
                });
                Lyta.log('Relevant: allowedDivIds');
                Lyta.log(JSON.parse(JSON.stringify(allowedDivIds)));
                relevantDigital.loadPrebid({
                    sasOptions: {
                        siteId: fst.siteId,
                        pageId: fst.pageId,
                        preloadSlots: false,
                        autoRender: true,
                        panicDisable: false, // set to true during Smart-downtimes to disable Smart-calls
                        callParams: { target: fst.target || '' },
                        callOptions: { getAdContent: true },
                        setup: that.setupOptions,
                        stdCall: that.config.stdCall ? formats : null,
                        onLoad: function (data) {
                            if (!data.hasAd && that.onNoAdCb) {
                                that.onNoAdCb(data);
                            }
                        },
                    },
                    configId: that.config.configId,
                    manageAdserver: true,
                    allowedDivIds: allowedDivIds,
                });
            });
            Lyta.groupEnd('Relevant: flushFormats ...');
        }
    };
    Relevant.init();

    let adFormats = {};
    let isViewable = false;
    let queueAds = false;
    let processAdQueueTimeout = null;
    let lowPriorityadQueue = [];
    let highPriorityAdQueue = [];
    let lazyLoadAds = false;
    let lazyAdsQueue = {};
    let useTcf = false;
    let tcfReady = false;

     // Create a responsive container and wrapper within an element
    // by given ID (adContainerId)
    //
    // Ad will be loaded in DIV element with ID "adContainerId-wrapper"
    // inside a DIV container with ID "adContainerId-container"
    //
    // The container is scaled down to fit within the space allocated
    // for its parent container. CSS transformations are used
    // for scaling

    const responsifyAd = (adContainerId) => {
        const adElement = document.getElementById(adContainerId);

        var naturalHeight = 0;
        var mutationEvent = false;

        if (!adElement) {
            return;
        }

        const createWrapper = (elem) => {
            var baseName = elem.id;
            // Inject container and wrapper to given element
            var container = document.createElement("div");
            var wrapper = document.createElement("div");

            container.id = baseName + "-container";
            wrapper.id = getWrapperId(baseName);
            container.appendChild(wrapper);
            adElement.appendChild(container);
            return container;
        }

        const container = createWrapper(adElement);

        const fitElement = () => {
            const offsetWidth = container.offsetWidth;
            const parentNode = container.parentNode;
            const naturalWidth = container.scrollWidth;

            var height, scale, naturalHeight;

            if (offsetWidth >= naturalWidth) {
                // The container fits in viewport, so no need to scale
                // Force overflow: hidden to mitigate cases where wide
                // iframe ads are slow to trigger onload event
                // and cause narrow viewports to overflow
                container.setAttribute("style", "overflow: hidden");
                parentNode.style.height = "";
                return;
            }

            height = container.scrollHeight;

            if (mutationEvent) {
                mutationEvent = false;
                if (naturalHeight == height) {
                    return;
                }
                naturalHeight = height;
            }

            // Determine scaling factor
            scale = Math.min(1, offsetWidth / naturalWidth);

            // Scale down parent node height and ad container
            parentNode.style.height = height * scale + "px";
            container.setAttribute("style",
                "-webkit-transform: scale(" + scale + ");" +
                "-moz-transform: scale(" + scale + ");" +
                "transform: scale(" + scale + ");" +
                "transform-origin: 0 0");
        };

        const dispatchUpdateEvent = () => {
            var event = new CustomEvent(LYTA_UPDATE_EVENT);
            mutationEvent = true;
            window.dispatchEvent(event);
        }

        // Define MutationObserver for updating ad dimensionson DOM changes
        const observer = new MutationObserver(function(mutationsList) {
            dispatchUpdateEvent();
            for (var i=0; i < mutationsList.length ; i++) {
                var mutation = mutationsList[i];
                if (mutation.type == 'childList') {
                    Array.prototype.forEach.call(mutation.target.children, (child) => {
                        var childOfInterest;
                        if (child.tagName === "A") {
                            childOfInterest = child.getElementsByTagName('IMG')[0]
                        }
                        if (child.tagName === "IFRAME") {
                            childOfInterest = child;
                        }
                        if (childOfInterest) {
                            childOfInterest.addEventListener('load', dispatchUpdateEvent, false);
                        }
                });
                }
            }
        });

        // Begin observing changes in adContainerId container
        observer.observe(document.getElementById(adContainerId), {
            childList: true,
            attributes: false,
            characterData: false,
            subtree: true
        });

        // Bind event handler to fit responsified element on LYTA_THROTTLED_FIT_EVENT
        window.addEventListener(LYTA_THROTTLED_FIT_EVENT, fitElement, false);

        return getWrapperId(adContainerId)
    };

    // https://gist.github.com/edwinwebb/e71bde8b00ff40f3f046
    const throttle = (func, wait, options) => {
        const _ = {
            now :  Date.now || function() {
            return new Date().getTime();
            }
        }
        var context, args, result;
        var timeout = null;
        var previous = 0;
        if (!options) options = {};
        const later = () => {
            previous = options.leading === false ? 0 : _.now();
            timeout = null;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        };
        return () => {
            var now = _.now();
            if (!previous && options.leading === false) previous = now;
            var remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
            } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
            }
            return result;
        };
    };

    // Bind event handlers to fit responsified elements on window resize or
    // LYTA_UPDATE_EVENT events.
    const dispatchFitEvent = () => {
        var event = new CustomEvent(LYTA_THROTTLED_FIT_EVENT);
        window.dispatchEvent(event);
    }
    const throttledFitEvent = throttle(dispatchFitEvent, ELEMENT_FIT_THROTTLE);

    window.addEventListener("resize", throttledFitEvent, false);
    window.addEventListener(LYTA_UPDATE_EVENT, throttledFitEvent, false);

    const getWrapperId = (containerId) => {
        return containerId + "-wrapper"
    }

    const clientType = () => {
        var client = "mobile";
        if ((typeof window.matchMedia == "function" && window.matchMedia("(min-width: 768px)").matches) ||
            (typeof window.matchMedia == "undefined" && window.innerWidth >= 768)) {
            client = "tablet";
        }
        if ((typeof window.matchMedia == "function" && window.matchMedia("(min-width: 980px)").matches) ||
            (typeof window.matchMedia == "undefined" && window.innerWidth >= 980)) {
            client = "desktop";
        }
        return client;
    };

    const clientPlatform = () => {
        var platform = "browser";
        var ua = navigator.userAgent || navigator.vendor;
        if (ua.indexOf("FBAN") > -1) {
            platform = "facebook_app";
        } else if (ua.indexOf("Twitter") > -1) {
            platform = "twitter_app";
        } else if (window && window.isMaggioView === true) {
            platform = "maggio";
        }
        return platform;
    };

    const isMobile = () => {
        return clientType() === "mobile"
    };

    const isDesktop = () => {
        return clientType() === "tablet" || clientType() === "desktop"
    };

    const getCookie = (name) => {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    const createCookie = (name, value, seconds) => {
        var expires = "";
        if (seconds) {
            var date = new Date();
            date.setTime(date.getTime() + (seconds * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    const clearCookie = (name) => {
        createCookie(name,"",-1);
    }

    const canShowAd = (adUnit) => {
        // Care only about out-of-page ad units (interstitial and "special ads")
        if (OUT_OF_PAGE_ADS.indexOf(adUnit) == -1) {
            return true;
        }
        // Interstitial requested, and not yet shown
        if (INTERSTITIAL_ADS.indexOf(adUnit) > -1 && Lyta.interstitialShown !== INTERSTITIAL_COOKIE) {
            createCookie(INTERSTITIAL_COOKIE, INTERSTITIAL_COOKIE, Lyta.interstitialCookieExpiry);
            return true;
        }
        // Special ad requested, interstitial already shown
        if (SPECIAL_ADS.indexOf(adUnit) > -1 && Lyta.interstitialShown == INTERSTITIAL_COOKIE) {
            return true;
        }
        // Can not show interstitial or special ad unit
        return false;
    };

    const getContentFormatID = () => {
        if (clientType() === "mobile") {
            return MOBILE_CONTENT_FORMAT_ID;
        } else {
            return DESKTOP_CONTENT_FORMAT_ID;
       }
    };

    const getContentAdunitNames = (position) => {
        var adUnitNames = ["content_0" + position];
        if (clientType() === "mobile") {
            adUnitNames.push("boksi" + position);
        }
        return adUnitNames;
    };

    const getContentTopId =() => {
        if (clientType() === "mobile") {
            return MOBILE_PARADE_ID;
        } else {
            return DESKTOP_PARADE_ID;
       }
    };

    const getContentTopName = () => {
        if (clientType() === "mobile") {
            return "mobiiliylaparaati";
        } else {
            return "ylaparaati";
       }
    };

    const getBelowContentName = () => {
        var adUnitNames = [BELOW_CONTENT_NAME];
        if (clientType() !== "mobile") {
            adUnitNames.push("boksi1");
        }
        return adUnitNames;
    };

    // Serialize given obj to a string separated with separator
    //
    // If the property is an array, each of the array elements
    // will be appended to the string with the name of the property
    //
    // For example:
    // obj.example = [1,2,3]
    // serialize(obj,';')
    // ->
    // example=1;example=2;example=3

    const serialize = (obj, separator) => {
        var separator = separator || "&";
        let str = [];
        for (let p in obj) {
            if (obj.hasOwnProperty(p)) {
                if (typeof obj[p] === "object" && obj[p].constructor === Array) {
                    for (let i in obj[p]) {
                        str.push(p + "=" + obj[p][i]);
                    }
                }
                else {
                    str.push(p + "=" + obj[p]);
                }
            }
        }
        return str.join(separator);
    };

    // Slugify given string
    // https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1

    const slugify = (string) => {
        const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
        const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return string.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, function(c) {return b.charAt(a.indexOf(c))}) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w\-]+/g, '') // Remove all non-word characters
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
    };

    // Build object for targeting

    const targetOptions = (adUnitName, adCallKeywords) => {
        let url = document.location.pathname + document.location.search;
        if (typeof window.digitalData?.page?.pageInfo?.locationPathName === 'string') {
            url = window.digitalData.page.pageInfo.locationPathName;
        }
        let target = {
            page_type: LytaConfig.target.pageType,
            client: clientType(),
            platform: clientPlatform(),
            url: url,
            adunit: adUnitName
        }

        if (typeof LytaConfig.target.adCategory === "string" && LytaConfig.target.adCategory.length > 0) {
            target.category = LytaConfig.target.adCategory;
        }

        if (typeof LytaConfig.target.assetId === "string" && LytaConfig.target.assetId.length > 0) {
            target.assetid = LytaConfig.target.assetId;
        }

        if (typeof LytaConfig.target.newsSubjects !== "undefined" && LytaConfig.target.newsSubjects.length > 0) {
            target.news_subject = LytaConfig.target.newsSubjects;
        }

        if (typeof LytaConfig.target.keywords === 'object' && LytaConfig.target.keywords.length > 0) {
            target.keyword = target.keyword || [];
            for (const keyword of LytaConfig.target.keywords) {
                target.keyword.push(slugify(keyword));
            }
        }

        if (typeof adCallKeywords === 'object' && adCallKeywords.length > 0) {
            target.keyword = target.keyword || [];
            for (const keyword of adCallKeywords) {
                target.keyword.push(slugify(keyword));
            }
        }

        if (typeof LytaConfig.target.user === 'object') {
            let user = LytaConfig.target.user;
            if (user.activeSubscriptions) {
                target.subs = user.activeSubscriptions;
            }
            target.loggedin = user.signInStatus;
        }

        return target;
    }

    const isInitialized = () => {
        return Lyta.initialized;
    }

    const renderQueuedAd = (instance, callNow) => {
        Relevant.callFormat(instance.adCall, callNow);
        if (instance.adLabels) {
            showAdLabels(instance.adUnitWrapper, instance.adContainer);
        }
    }

    const processAdQueue = (queue, dequeue) => {
        Lyta.group('processAdQueue called ...');
        Lyta.log(JSON.parse(JSON.stringify(queue)));
        var dequeue = dequeue === undefined ? false : dequeue;
        let queueItem;

        if (useTcf && !tcfReady) {
            Lyta.log('... but TCF is not ready for ad queue.');
            window.addEventListener(TCF_READY_EVENT, function() {
                Lyta.log(`processAdQueue triggered by ${TCF_READY_EVENT} event listener`);
                processAdQueue(queue, dequeue);
            }, false);
            return Lyta.groupEnd();
        }
        if (dequeue) {
            queueAds = false;
            Lyta.log('Ad queuing set to disabled. Further ad loads will no longer be queued.')
        }
        Lyta.log('Started processing queued ads.')
        queue.reverse();
        while (queueItem = queue.pop()) {
            renderQueuedAd(queueItem, false);
            Lyta.log(queueItem.adUnit + ' called from queue');
        }
        Relevant.flushFormats();
        Lyta.groupEnd();
    }

    const tcfCallback = (tcData, success) => {
        Lyta.group('tcfCallback');
        if (success) {
            if (tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'useractioncomplete') {
                if (!tcfReady) {
                    Lyta.log('Flagging TCF ready, processing queued ads, and triggering ' + TCF_READY_EVENT);
                    // Flag TCF as ready and process queued ads
                    tcfReady = true;
                    processAdQueue(highPriorityAdQueue, false);
                    // Dispatch TCF_READY_EVENT
                    var event = new CustomEvent(TCF_READY_EVENT);
                    window.dispatchEvent(event);
                    // Remove event listener
                    __tcfapi('removeEventListener', 2, function(success) {
                        if (success) {
                            Lyta.log('removed EventListener for tcfCallback');
                        } else {
                            Lyta.log('Failed to remove EventListener for tcfCallback');
                        }
                    }, tcData.listenerId);
                } else {
                    Lyta.log('TCF already ready');
                }
            }
        } else {
            Lyta.log('Failed');
        }
        Lyta.groupEnd();
    }

    const viewableChangeListener = (viewable) => {
        isViewable = viewable;
        if (isViewable && queueAds) {
            window.clearTimeout(processAdQueueTimeout);
            processAdQueue(lowPriorityadQueue, true);
        }
    };

    // Process lazy ad
    const processLazyAd = (adContainerId) => {
        let adCallObject = lazyAdsQueue[adContainerId];

        if (adCallObject) {
            if (useTcf && !tcfReady) {
                Lyta.log('TCF not ready for lazy ad. Queuing instead.');
                lowPriorityadQueue.push(adCallObject);
                return
            } else {
                renderQueuedAd(adCallObject, true);
                Lyta.log(adContainerId + ' lazy loaded');
            }
        }
    };

    // Show generic ad labels inside ad unit container

    const showAdLabels = (wrapperId, adContainer) => {
        showLabelTimeout = window.setTimeout(function() {
            Lyta.log(`Show ad labels after ${LABEL_TIMEOUT} timeout for ${wrapperId}`)
            let element = document.getElementById(wrapperId);
            let positions = ["beforebegin", "afterend"];
            let title = { beforebegin: "Juttu jatkuu mainoksen jälkeen", afterend: "Juttu jatkuu" };
            let html = '';
            if (LytaConfig.site == MAGGIO_APPLICATION_SITE && adContainer == BELOW_CONTENT_NAME) {
                positions = ["beforebegin"];
                title = { beforebegin: "Mainos" };
            }
            for (let i = 0; i < positions.length; i++) {
                if (!document.getElementById(adContainer + '-label-' + positions[i])) {
                    html = '<div id="' + adContainer + '-label-' + positions[i] + '" class="lyta-label-' + positions[i] + '">' + title[positions[i]] + '</div>';
                    element.insertAdjacentHTML(positions[i], html);
                }
            }
        }, LABEL_TIMEOUT);
    }

    // Default format for non-Lorien sites

    adFormats['default'] = {
        // placement_name: [format_id, enabled_for_client, adunit_name, responsify]
        // placement_name - (string) Key used for calling the specific ad unit
        // format_id - (int) ID of the ad format in Smart Adserver
        // enabled_for_client - (bool) should the ad be shown for the client
        // adunit_name - (string or array) value of the "adunit" key passed to Smart
        //               this is used to target advertisements that share
        //               their format ID (boksi, viikkoruutu)
        // responsify - (bool) should the advertisement be wrapped in
        //              a container that is scaled to fit within its parent
        ylaparaati: [DESKTOP_PARADE_ID, isDesktop(), "ylaparaati", true],
        mobiiliylaparaati: [MOBILE_PARADE_ID, isMobile(), "mobiiliylaparaati", true],
        boksi1: [BOX_FORMAT_FORMAT_ID, isDesktop(), "boksi1", true],
        boksi2: [BOX_FORMAT_FORMAT_ID, isDesktop(), "boksi2", true],
        boksi3: [BOX_FORMAT_FORMAT_ID, isDesktop(), "boksi3", true],
        boksi4: [63773, isDesktop(), "boksi4", true],
        mobiiliboksi1: [BOX_FORMAT_FORMAT_ID, isMobile(), "boksi1", true],
        mobiiliboksi2: [BOX_FORMAT_FORMAT_ID, isMobile(), "boksi2", true],
        mobiiliboksi3: [BOX_FORMAT_FORMAT_ID, isMobile(), "boksi3", true],
        mobiiliboksi4: [63773, isMobile(), "boksi4", true],
        mobiiliextraboksi: [64879, isMobile(), "extraboksi", true],
        omniboksi1: [BOX_FORMAT_FORMAT_ID, true, "boksi1", true],
        omniboksi2: [BOX_FORMAT_FORMAT_ID, true, "boksi2", true],
        omniboksi3: [BOX_FORMAT_FORMAT_ID, true, "boksi3", true],
        omniboksi4: [63773, true, "boksi4", true],
        jattiboksi: [63814, isDesktop(), "jattiboksi", true],
        suurtaulu: [63768, isDesktop(), "suurtaulu", true],
        alaparaati: [63822, isDesktop(), "alaparaati", true],
        outstream: [OUTSTREAM_FORMAT_ID, true, "outstream", true],
        hoksaacom: [63189, true, "hoksaacom", false],
        adswiper: [63770, true, "adswiper", false],
        header: [63769, isDesktop(), "header", false],
        erikoismainos: [63008, isDesktop(), "erikoismainos", false],
        mobiilierikoismainos: [63007, isMobile(), "mobiilierikoismainos", false],
        erikoismainos_ekaleva: [65117, true, "erikoismainos_ekaleva", false],
        viikkoruutu1: [63815, isDesktop(), "viikkoruutu1", false],
        viikkoruutu2: [63815, isDesktop(), "viikkoruutu2", false],
        viikkoruutu3: [63815, isDesktop(), "viikkoruutu3", false],
        viikkoruutu4: [63815, isDesktop(), "viikkoruutu4", false],
        viikkoruutu5: [63815, isDesktop(), "viikkoruutu5", false],
        viikkoruutu6: [63815, isDesktop(), "viikkoruutu6", false]
    };

    adFormats['sites-lorien'] = {
        // placement_name: [format_id, enabled_for_client, adunit_name, responsify]
        // placement_name - (string) Key used for calling the specific ad unit
        // format_id - (int) ID of the ad format in Smart Adserver
        // enabled_for_client - (bool) should the ad be shown for the client
        // adunit_name - (string or array) value of the "adunit" key passed to Smart
        //               this is used to target advertisements that share
        //               their format ID (boksi, viikkoruutu)
        // responsify - (bool) should the advertisement be wrapped in
        //              a container that is scaled to fit within its parent
        ylaparaati: [DESKTOP_PARADE_ID, isDesktop(), "ylaparaati", true],
        mobiiliylaparaati: [MOBILE_PARADE_ID, isMobile(), "mobiiliylaparaati", true],
        sidebar1: [BOX_FORMAT_FORMAT_ID, isDesktop(), ["sidebar_01", "boksi1"], true],
        sidebar2: [BOX_FORMAT_FORMAT_ID, isDesktop(), ["sidebar_02", "boksi2"], true],
        sidebar3: [BOX_FORMAT_FORMAT_ID, isDesktop(), ["sidebar_03", "boksi3"], true],
        contentTop: [OUTSTREAM_FORMAT_ID, true, "outstream", true],
        belowcontent: [BOX_FORMAT_FORMAT_ID, isMobile(), BELOW_CONTENT_NAME, true],
        adswiper: [63770, true, "adswiper", false],
        erikoismainos: [63008, isDesktop(), "erikoismainos", false],
        mobiilierikoismainos: [63007, isMobile(), "mobiilierikoismainos", false],
        interstitiaali: [81021, isDesktop(), "interstitiaali", false],
        mobiiliinterstitiaali: [81037, isMobile(), "mobiiliinterstitiaali", false],
        viikkoruutu1: [63815, true, "viikkoruutu1", false],
        viikkoruutu2: [63815, true, "viikkoruutu2", false],
        viikkoruutu3: [63815, true, "viikkoruutu3", false],
        viikkoruutu4: [63815, true, "viikkoruutu4", false],
        viikkoruutu5: [63815, true, "viikkoruutu5", false],
        viikkoruutu6: [63815, true, "viikkoruutu6", false],
    };
    for (let i = 1; i <= 7; i++) {
        adFormats['sites-lorien']['content' + i] = [getContentFormatID(), true, getContentAdunitNames(i), true];
    }

    adFormats[MAGGIO_APPLICATION_SITE] = {
        // placement_name: [format_id, enabled_for_client, adunit_name, responsify]
        // placement_name - (string) Key used for calling the specific ad unit
        // format_id - (int) ID of the ad format in Smart Adserver
        // enabled_for_client - (bool) should the ad be shown for the client
        // adunit_name - (string or array) value of the "adunit" key passed to Smart
        //               this is used to target advertisements that share
        //               their format ID (boksi, viikkoruutu)
        // responsify - (bool) should the advertisement be wrapped in
        //              a container that is scaled to fit within its parent
        contentTop: [getContentTopId(), true, getContentTopName(), true],
        content1: [getContentFormatID(), true, getContentAdunitNames(1), true],
        content2: [getContentFormatID(), true, getContentAdunitNames(2), true],
        content3: [getContentFormatID(), true, getContentAdunitNames(3), true],
        content4: [getContentFormatID(), true, getContentAdunitNames(4), true],
        content5: [getContentFormatID(), true, getContentAdunitNames(5), true],
        belowcontent: [BOX_FORMAT_FORMAT_ID, true, getBelowContentName(), true],
    };

    adFormats['sites-tapahtumat'] = {
        // placement_name: [format_id, enabled_for_client, adunit_name, responsify]
        // placement_name - (string) Key used for calling the specific ad unit
        // format_id - (int) ID of the ad format in Smart Adserver
        // enabled_for_client - (bool) should the ad be shown for the client
        // adunit_name - (string or array) value of the "adunit" key passed to Smart
        //               this is used to target advertisements that share
        //               their format ID (boksi, viikkoruutu)
        // responsify - (bool) should the advertisement be wrapped in
        //              a container that is scaled to fit within its parent
        ylaparaati: [DESKTOP_PARADE_ID, isDesktop(), "ylaparaati", true],
        mobiiliylaparaati: [MOBILE_PARADE_ID, isMobile(), "mobiiliylaparaati", true],
    };


    Lyta.log = (message) => {
        if(!LytaConfig.debug) {
            return;
        }
        console.log(message);
    }

    Lyta.group = (group) => {
        if(!LytaConfig.debug) {
            return;
        }
        console.group(group);
    }

    Lyta.groupEnd = () => {
        if(!LytaConfig.debug) {
            return;
        }
        console.groupEnd();
    }

    Lyta.init = () => {
        if (isInitialized()) {
            return true;
        }
        const scheme = ('http:' == document.location.protocol && LytaConfig.smart.forceSSL !== true ? 'http://' : 'https://');
        const head = document.head || document.documentElement;
        const script = document.createElement("script");

        window.sas = window.sas || {};
        var sas = window.sas;
        sas.cmd = sas.cmd || [];

        let smartSetupParameters = {
            networkid: LytaConfig.smart.networkId,
            domain: scheme + LytaConfig.smart.host,
            async: true
        };

        if (typeof LytaConfig.uidCookieName === "string" && (uid = getCookie(LytaConfig.uidCookieName))) {
            smartSetupParameters.uid = uid;
        }

        Relevant.setup(smartSetupParameters);

        script.async = false;
        script.src = scheme + "ced.sascdn.com/tag/" + LytaConfig.smart.networkId + "/smart.js";

        head.insertBefore(script, head.firstChild);

        // Use site specific ad format mapping, if available
        // Revert to default mapping if site specific mapping does not exist

        Lyta.adFormats = adFormats[LytaConfig.site] || adFormats['default'];

        // Read and cache interstitial ad parameters
        Lyta.interstitialShown = getCookie(INTERSTITIAL_COOKIE);
        Lyta.interstitialCookieExpiry = LytaConfig.interstitialCookieExpiry || INTERSTITIAL_COOKIE_EXPIRY;

        // Listen for MRAID viewability events if mraid is defined

        if (typeof mraid !== "undefined") {
            queueAds = true;
            mraid.addEventListener("viewableChange", viewableChangeListener);
        }

        // Use TCF v2 API if available
        if (typeof window.__tcfapi == "function") {
            useTcf = true;
            Lyta.log('__tcfapi in use. All ad loads will be deferred until TCF is ready.');
            // Do stuff
            __tcfapi('addEventListener', 2, tcfCallback);
        }

        // Automatically load queued advertisements after predefined delay
        Lyta.queueTime = LytaConfig.queueTime || AD_QUEUE_DELAY;
        if (LytaConfig.queueAds === true) {
            queueAds = true;
            Lyta.log(`Ad queue enabled with ${Lyta.queueTime} ms timeout.`);
            processAdQueueTimeout = window.setTimeout(function() {
                processAdQueue(lowPriorityadQueue, true);
            }, Lyta.queueTime);
        }

        // Lazy load ads
        // https://github.com/verlok/lazyload
        if (LytaConfig.lazyLoadAds === true) {
            lazyLoadAds = true;

            // Lazy load options
            window.lazyLoadOptions = {
                threshold: 400,
                elements_selector: ".lyta-lazy-load",
                callback_reveal: function (el) {
                    processLazyAd(el.id);
                }
            };

            // Event listener for LazyLoad init
            window.addEventListener(
                "LazyLoad::Initialized",
                function (event) {
                    Lyta.log('Lazy load: initialized');
                    window.lazyLoadInstance = event.detail.instance;
                },
                false
            );

            // Event listener for LazyLoad to check the DOM again after browser has loaded all the DOM
            document.addEventListener('DOMContentLoaded', function (event) {
                if (window.lazyLoadInstance) {
                    Lyta.log('Lazy load: updated lazy load instance');
                    window.lazyLoadInstance.update();
                }
            });
        }

        // Callback for onNoad event
        // Applies to all ad units
        Relevant.setOnNoad(function(data) {
            var tagId = data.tagId;
            var container = data.tagId.replace('-wrapper', '');
            document.getElementById(tagId).style.display = 'none';
            document.getElementById(container).style.display = 'none';
        });

        Lyta.initialized = true;
        Lyta.log(`Lyta version ${Lyta.version} initialized.`);
        return Lyta;
    }

    Lyta.refresh = (options) => {
        isInitialized() || Lyta.init();
        options = options === undefined ? {} : options;
        const adUnit = options.adUnit === undefined ? false : options.adUnit;
        const adContainer = options.adContainer === undefined ? adUnit : options.adContainer;
        const forceMasterFlag = options.forceMasterFlag === undefined ? false : options.forceMasterFlag;
        const resetTimestamp = options.resetTimestamp === undefined ? true : options.resetTimestamp;
        const keywords = options.keywords === undefined ? false : options.keywords;

        if (!adUnit) {
            Lyta.log("Refresh: Ad unit not not defined.");
            return;
        }

        Lyta.log(adUnit + " refresh requested, formatId = " + Lyta.adFormats[adUnit][FORMATID] + ")");

        const adUnitName = Lyta.adFormats[adUnit][ADUNIT_NAME];
        const target = targetOptions(adUnitName, keywords);
        const adCall = {
            forceMasterFlag: forceMasterFlag,
            resetTimestamp: resetTimestamp,
            target: serialize(target, ";")
        }

        let adUnitWrapper = adContainer;

        if (Lyta.adFormats[adUnit][RESPONSIFY]) {
            adUnitWrapper = getWrapperId(adContainer);
        }

        sas.refresh(adUnitWrapper, adCall);

        Lyta.log(JSON.parse(JSON.stringify(adUnit)));
        Lyta.log(JSON.parse(JSON.stringify(adCall)));
    }

    Lyta.render = (options) => {
        isInitialized() || Lyta.init();
        options = options === undefined ? {} : options;
        const adUnit = options.adUnit === undefined ? false : options.adUnit;
        const adContainer = options.adContainer === undefined ? adUnit : options.adContainer;
        const async = options.async === undefined ? true : options.async;
        const checkVisibility = options.checkVisibility === undefined ? false : !!options.checkVisibility;
        const onNoad = options.onNoad === undefined ? undefined : options.onNoad;
        const adLabels = options.adLabels === undefined ? false : options.adLabels;
        const keywords = options.keywords === undefined ? false : options.keywords;

        if (!adUnit) {
            Lyta.log("Ad unit not not defined.");
            return;
        }

        const elem = document.getElementById(adContainer);
        const adUnitName = Lyta.adFormats[adUnit][ADUNIT_NAME];

        Lyta.group(adUnit);

        Lyta.log(JSON.parse(JSON.stringify({
            call: 'Lyta.render',
            adUnitPlacementID : adUnit,
            formatId : Lyta.adFormats[adUnit][FORMATID],
            checkVisibility : checkVisibility,
            isVisible: elem.offsetParent === null,
            responsifyAd : Lyta.adFormats[adUnit][RESPONSIFY],
            enabledForClient : Lyta.adFormats[adUnit][ENABLED_FOR_CLIENT],
            canShowAd: !canShowAd(adUnit),
            adLabels: adLabels,
            options: options,
        })));

        if (!Lyta.adFormats[adUnit][ENABLED_FOR_CLIENT]) {
            Lyta.log('Skipping - Not enabled');
            return Lyta.groupEnd();
        }
        if (elem.offsetParent === null && checkVisibility) {
            Lyta.log('Skipping - Not visible');
            return Lyta.groupEnd();
        }
        if (!canShowAd(adUnit)) {
            Lyta.log('Skipping - Can not show on this page load');
            return Lyta.groupEnd();
        }

        let adUnitWrapper = adContainer;

        if (Lyta.adFormats[adUnit][RESPONSIFY]) {
            adUnitWrapper = responsifyAd(adContainer);
        }

        const target = targetOptions(adUnitName, keywords);

        let adCall = {
            siteId: LytaConfig.smart.siteId,
            pageId: LytaConfig.smart.pageId,
            formatId: Lyta.adFormats[adUnit][FORMATID],
            async: async,
            target: serialize(target, ";"),
            tagId: adUnitWrapper
        }

        if (typeof onNoad === "function") {
            adCall.onNoad = onNoad;
        }

        let lazyAd = false;
        if (lazyLoadAds && elem.className.indexOf('lyta-lazy-load') > -1) {
            lazyAd = true;
        }

        // Queue lower priority advertisements, call PRIORITY_ADS as soon
        // as sas is ready.
        //
        // Handle lazy load ads with lyta-lazy-load class name

        let wasQueued = false;
        const adCallObject = {
            adUnit: adUnit,
            adCall: adCall,
            adUnitWrapper: adUnitWrapper,
            adContainer: adContainer,
            adLabels: adLabels,
        };

        if (lazyAd) {
            lazyAdsQueue[adContainer] = adCallObject;
            wasQueued = true;
            Lyta.log('Will be loaded lazily');
        } else if (queueAds) {
            // If TCF is NOT in use or if TCF is ready, render high priority
            // ads immediately. TCF can only be ready if it is in use.
            const shouldRender = (!useTcf || tcfReady);

            // It is sufficient to check if the adUnit is in the PRIORITY_ADS array.
            // canShowAd(adUnit) takes care of INTERSTITIAL_ADS throttling
            if (PRIORITY_ADS.indexOf(adUnit) > -1) {
                if (shouldRender) {
                    Relevant.callFormat(adCall, true);
                    Lyta.log('Rendered');
                } else {
                    highPriorityAdQueue.push(adCallObject);
                    wasQueued = true;
                    Lyta.log('Queued to priority queue');
                }
            } else {
                lowPriorityadQueue.push(adCallObject);
                wasQueued = true;
                Lyta.log('Queued to low priority queue');
            }
        }
        // Render ad immediately if queue is not used or already processed
        else {
            Relevant.callFormat(adCall, true);
        }
        // Show ad labels if ad was not queued
        if (adLabels && !wasQueued) {
            showAdLabels(adUnitWrapper, adContainer);
        }
        Lyta.groupEnd();
    };
    return Lyta.init();
}();
